import React, { useState } from "react"
import "swiper/swiper-bundle.css"
import harvia from "../../assets/logos/harvia.svg"
import hexor from "../../assets/logos/hexor.png"
import huum from "../../assets/logos/huum.svg"
import qualitystone from "../../assets/logos/qualitystone.jpg"
import saunum from "../../assets/logos/Saunum.svg"
import unknown from "../../assets/logos/unknown.svg"
import emotion_walls from "../../assets/logos/emotion_walls.png"
import PartnerText from "./PartnerText"
import { LogosInnerWrapper, LogosWrapper, Wrapper } from "./styles"

const logos = [
  {
    id: 1,
    src: saunum,
    alt: "Saunum",
  },
  {
    id: 2,
    src: qualitystone,
    alt: "Qualitystone",
  },
  {
    id: 3,
    src: huum,
    alt: "Huum",
  },
  {
    id: 4,
    src: emotion_walls,
    alt: "Emotion Walls",
  },
  {
    id: 5,
    src: harvia,
    alt: "Harvia",
  },
  {
    id: 6,
    src: unknown,
    alt: "Uknknown",
  },
]

const content = [
  {
    id: 1,
    title: "Saunum",
    content:
      "Saunumi ainulaadne tehnoloogia pakub nauditavamat ja tervislikumat saunaelamust. Tõeliselt kaasaegset ja põhjamaist tehnoloogiat, mis muudab saunatamise kõigile nauditavaks, kadestavad isegi soomlased.",
  },
  {
    id: 2,
    title: "Qualitystone",
    content:
      "Käsitsi lõigatud unikaalsed marmorist kivimosaiigid ja -plaadid saunade, spaade, vannitubade, terrasside, köökide ja muu jaoks.",
  },
  {
    id: 3,
    title: "Huum",
    content:
      "Eesti ettevõtte HUUMi keriste ümar ja minimalistlik disain rõhutab kivide loomulikku ilu ning elavdab leiliruumi sisekujundust. Huumi kerised, mida juhib tehnoloogia läbi sinu nutiseadme, on saunamaailma revolutsioon. ",
  },
  {
    id: 4,
    title: "Emotion Walls",
    content:
      "Emotionwallsi täispuidust seinapaneelid sobivad kasutamiseks nii elutuppa, sauna, trepihalli kui ka magamistuppa. Valikus on erinevad puiduliigid nagu tamm, saar, haab, pähkel, samuti termotöödeldud ja põletatud puidu lahendused. 100% naturaalne ja kordumatult ilus.",
  },
  {
    id: 5,
    title: "Harvia",
    content:
      "Soome ettevõte, mis on arenenud saunakeriste tootjast tunnustatud sauna- ja spaabrändiks, mille tooteid ja lahendusi võib leida rohkem kui 80 riigis üle maailma. Meil on tugev koostööpartner Harvia Estonia näol.",
  },
  {
    id: 6,
    title: "Khis",
    content:
      "Iga KHIS vann on unikaalne ja käsitsi tehtud: alates puulippide lõikamisest, nende viimistlusest kuni vanni kokku panemiseni. Aeg tagab täpsuse, pühendumine tagab kvaliteedi.TTunneta kvaliteetpuidu soojust ja luksust oma vannitoas.",
  },
]

const Partners = props => {
  const [selection, setSelection] = useState(1)

  return (
    <Wrapper>
      <h2>Meie partnerid</h2>
      <LogosWrapper>
        {logos.map(logo => {
          return (
            <LogosInnerWrapper
              key={logo.id}
              onClick={() => setSelection(p => (p != logo.id ? logo.id : p))}
            >
              <img src={logo.src} alt={logo.alt} />
            </LogosInnerWrapper>
          )
        })}
      </LogosWrapper>
      {selection &&
        content.map(c => {
          if (c.id !== selection) return
          const logo = logos.filter(el => el.id === c.id)
          return (
            <PartnerText key={c.id} title={c.title} text={c.content}>
              <img src={logo[0].src} />
            </PartnerText>
          )
        })}
    </Wrapper>
  )
}

export default Partners
