import React from "react"
import { InputWrapper } from "./styles"

const Input = props => {
  const { name, value, type, placeholder, title, vale } = props || {}

  return (
    <InputWrapper vale={vale}>
      <label htmlFor={name}>{title}</label>
      {type === "textarea" ? (
        <textarea
          rows="5"
          name={name}
          id={name}
          placeholder={placeholder}
          value={value || ""}
          onChange={e => props.onChange(e.target.value, name)}
        ></textarea>
      ) : (
        <input
          name={name}
          id={name}
          type={type || "text"}
          placeholder={placeholder}
          value={value || ""}
          onChange={e => props.onChange(e.target.value, name)}
        />
      )}
    </InputWrapper>
  )
}

export default Input
