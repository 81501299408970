import React from "react"
import {
  BottomLeft,
  BottomRight,
  TextInnerWrapper,
  TextWrapper,
  TopRight,
} from "./styles"

const PartnerText = props => (
  <TextWrapper>
    <TopRight>{rightDecor}</TopRight>
    <BottomLeft>{bottomLeftDecor}</BottomLeft>
    <BottomRight>{bottomRightDecor}</BottomRight>
    <TextInnerWrapper>
      {props.children}
      <h4>{props.title}</h4>
      <p>{props.text}</p>
    </TextInnerWrapper>
  </TextWrapper>
)

const rightDecor = (
  <svg width="105.792" height="102.842" viewBox="0 0 105.792 102.842">
    <path
      d="M22.376,9.184C29.247,5.713,62.117-5.191,72.361,2.958S80.3,21.517,80.3,37.539s2.309,21.636-7.935,29.509-40.454,7.873-59.427,0S.55,38.5,3.765,31.045,15.506,12.655,22.376,9.184Z"
      transform="matrix(-0.875, 0.485, -0.485, -0.875, 105.792, 63.806)"
      fill="#d6be98"
      opacity="0.2"
    />
  </svg>
)

const bottomLeftDecor = (
  <svg width="77.031" height="83.896" viewBox="0 0 77.031 83.896">
    <path
      d="M19.75,19.979C22.923,16.911,38.182,7.6,43.1,15.563S59.085,38.87,59.306,53.945,48.676,68.754,43.982,75.867s-18.856,6.24-27.859-1.716-6.2-27.218-4.8-34.141S16.577,23.046,19.75,19.979Z"
      transform="translate(-14.683 18.899) rotate(-30)"
      fill="#d6be98"
      opacity="0.2"
    />
  </svg>
)

const bottomRightDecor = (
  <svg width="70.249" height="70.495" viewBox="0 0 70.249 70.495">
    <path
      d="M11.107,6.272C14.517,3.9,30.833-3.546,35.918,2.02s15.02,4.364,15.02,15.307S41,40.414,35.918,45.791s-20.08,5.377-29.5,0S.273,26.295,1.869,21.2,7.7,8.643,11.107,6.272Z"
      transform="translate(29.941 70.495) rotate(-126)"
      fill="#d6be98"
      opacity="0.2"
    />
  </svg>
)

export default PartnerText
