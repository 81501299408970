import styled from "styled-components"

export const Wrapper = styled.div`
  input {
    background-color: #7b6244;
    color: #fff;
    padding: 0.5rem 2.5rem;
    font-weight: 500;
    border: unset;
    border-radius: 0.25rem;
    max-width: 186px;
    margin: auto;
    display: block;
    @media only screen and (min-width: 1024px) {
      font-size: 20px;
      width: 186px;
      height: 45px;
    }
  }
`
