import React, { useState } from "react"
import { Close, Frame, ImageWrapper, SubWrapper, ViewButton } from "./styles"

const SubProductCard = props => {
  const { image, category, content, view, title } = props || {}
  const [modal, setModal] = useState(false)

  function toggle() {
    setModal(!modal)
  }

  return (
    <SubWrapper category={category}>
      <p>{content}</p>
      <ImageWrapper>
        <ViewButton onClick={toggle}>360 vaade</ViewButton>
        <img src={image} alt={title} />
      </ImageWrapper>
      {modal ? (
        <Frame
          onClick={e => (e.target.id === "frame" ? toggle() : null)}
          id="frame"
        >
          <Close onClick={toggle}>
            <span></span>
            <span></span>
          </Close>
          <iframe src={view} width="90%" height="80%" frameBorder="0"></iframe>
        </Frame>
      ) : null}
    </SubWrapper>
  )
}

export default SubProductCard
