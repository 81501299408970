import React from "react"
import { Wrapper } from "./styles"

const Form = props => (
  <Wrapper>
    <h2>{props.title}</h2>
    <form onSubmit={props.onSubmit}>{props.children}</form>
  </Wrapper>
)

export default Form
