import React from "react"
import { Wrapper } from "./styles"

const Submit = props => {
  const { title } = props || {}

  return (
    <Wrapper>
      <input type="submit" value={title} />
    </Wrapper>
  )
}

export default Submit
