import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Main from "../components/Main"
import SEO from "../components/seo"

export default function Home({ data }) {
  const page = useStaticQuery(graphql`
    query {
      wpPage(slug: { in: "avaleht" }) {
        title
        seo {
          metaDesc
          metaKeywords
        }
      }
    }
  `)

  console.log("data", page)

  return (
    <>
      <SEO title={page.wpPage.title} description={page.wpPage?.seo?.metaDesc} />
      <Main data={data} />
    </>
  )
}
