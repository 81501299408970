import styled, { css } from "styled-components"
import Banner from "../../assets/images/banner.png"

export const BannerWrapper = styled.div`
  width: 100%;
  background-color: #f1f5f8;
  background-image: url(${Banner});
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  min-height: 70vh;
  img {
    display: none;
  }
  @media only screen and (min-width: 1024px) {
    overflow: hidden;
    height: 105vh;
    img {
      display: block;
      width: calc(100% + 4px);
      bottom: -7px;
      left: 0;
      position: absolute;
      margin-bottom: 0;
      right: 0px;
      transform: translateX(1px);
    }
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 98px;
  }
  @media only screen and (min-width: 1440px) {
    height: 108vh;
  }
  @media only screen and (min-width: 1700px) {
    mask-size: cover;
  }
`
export const BannerInnerWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 25px;
    width: 250px;
    color: #fff;
    z-index: 1;
  }

  @media only screen and (min-width: 768px) {
    h2 {
      font-size: 40px;
      width: 450px;
    }
  }
  @media only screen and (min-width: 1024px) {
    top: 45%;
    h2 {
      font-size: 58px;
      width: 600px;
      color: #2b2928;
    }
  }
  @media only screen and (min-width: 1200px) {
    h2 {
      font-size: 64px;
      width: 700px;
    }
  }
  @media only screen and (min-width: 1440px) {
    top: 43%;
  }
`

export const DecorWrapper = styled.div`
  opacity: 0.25;
  visibility: visible;
  svg {
    z-index: 0;
    border-radius: 50%;
    width: 212px;
    height: 212px;
  }
  @media screen and (min-width: 768px) {
    svg {
      width: 100%;
      height: 100%;
      width: 350px;
      height: 350px;
    }
  }
  @media screen and (min-width: 1024px) {
    opacity: 0.5;
    svg {
      width: 500px;
      height: 500px;
    }
  }
`

export const Introduction = styled.div`
  img {
    margin-top: -200px;
    z-index: 1;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    order: -1;
    width: 200px;
    margin-bottom: 2rem;
  }
  @media only screen and (min-width: 1024px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    ${props =>
      props.space &&
      css`
        justify-content: space-evenly;
      `}
    ${props =>
      props.negativeMt &&
      css`
        img {
          order: 0;
          width: 350px;
          margin-top: -250px;
        }
      `}
  }
  @media only screen and (min-width: 1200px) {
    ${props =>
      props.negativeMt &&
      css`
        img {
          max-width: 500px;
          width: 450px;
          margin-top: -275px;
        }
      `}
  }
  @media only screen and (min-width: 1440px) {
    img {
      max-width: 100%;
      width: 550px;
    }
  }
  ${props =>
    props.wrap &&
    css`
      @media only screen and (min-width: 768px) {
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        div {
          flex: 0 0 calc(50% - 1rem);
          margin: 0.5rem;
        }
      }
      @media only screen and (min-width: 1024px) {
        flex-wrap: wrap;
        justify-content: space-between;
        div {
          flex: 0 0 32.5%;
          margin: 0;
        }
      }
    `}
`
export const Decor = styled.div`
  display: none;
  @media only screen and (min-width: 1024px) {
    display: flex;
    justify-content: center;
    margin: 4rem 0;
    img {
      margin: 0;
      width: 300px;
    }
  }
`

export const BlogWrapper = styled.div`
  margin: 2rem 0;
  h2 {
    color: #3f4a59;
    font-weight: 700;
    font-size: 32px;
    text-transform: uppercase;
  }
  @media only screen and (min-width: 1024px) {
    margin-top: 7rem;
    h2 {
      margin: 2rem 0;
      margin-bottom: 5rem;
      text-align: center;
      font-size: 52px;
    }
  }
`
export const ParntersWrapper = styled.div``

export const ParnerLogo = styled.div``

export const PartnerText = styled.div``

export const FormWrapper = styled.div`
  margin: 3rem 0;
  position: relative;
  @media only screen and (min-width: 1024px) {
    margin-top: 10rem;
    margin-bottom: 12rem;
  }
`

export const Anchor = styled.div`
  position: absolute;
  width: 100%;
  top: -150px;
  height: 10px;
`

export const FormMessage = styled.div`
  p {
    margin-top: 2rem;
    margin-bottom: 0;
    font-size: 16px;
    text-align: center;
    @media only screen and (min-width: 1024px) {
      text-size: 18px;
    }
  }
  ${props =>
    props.success &&
    css`
      p {
        color: #24db7d;
      }
    `}
  ${props =>
    props.error &&
    css`
      p {
        color: #d83227;
      }
    `}
`
