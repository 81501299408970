import styled, { css } from "styled-components"

export const Wrapper = styled.div`
  width: 310px;
  transition: 1s ease;
  div {
    transition: 1s ease;
  }
  @media only screen and (max-width: 415px) {
    width: 100%;
  }
  h2 {
    font-size: 32px;
    font-weight: 700;
    ${props =>
      props.variant === "brown" &&
      css`
        color: #7b6244;
      `}
    ${props =>
      props.variant === "teal" &&
      css`
        color: #3a686e;
      `}
  }
  p {
    font-size: 16px;
    line-height: 30px;
  }
  @media only screen and (min-width: 1024px) {
    h2 {
      font-size: 42px;
      margin-bottom: 1.75rem;
    }
    p {
      font-size: 16px;
    }
  }
`
