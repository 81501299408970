import styled, { css } from "styled-components"
import view from "../../../assets/icons/360.svg"

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  background-color: #3a3a3a;
  border-radius: 0.5rem;
  width: 295px;
  margin: auto;
  padding: 1.5rem 0.75rem;
  @media only screen and (min-width: 1024px) {
    display: block;
    max-width: 400px;
    padding: 1.5rem;
    min-height: 550px;
    margin: auto;
    width: 100%;
  }
`

export const Content = styled.div`
  p {
    font-size: 12px;
    color: #fff;
    line-spacing: 17px;
  }
  @media only screen and (max-width: 414px) {
    p {
      max-width: 200px;
    }
  }
  @media only screen and (min-width: 1024px) {
    p {
      font-size: 15px;
      line-spacing: 25px;
    }
  } ;
`

export const Product = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  h3 {
    font-size: 21px;
    font-weight: 700;
    color: #fff;
    margin-top: 0;
    text-align: center;
  }
  h4 {
    display: none;
  }
  @media only screen and (min-width: 1024px) {
    display: block;
    img {
      margin: auto;
      display: block;
      margin-top: -200px;
      margin-bottom: 0;
    }
    h3 {
      font-size: 34px;
      text-align: left;
    }
    h4 {
      display: block;
      margin-bottom: 0;
      color: #a9bfc1;
      font-size: 21px;
      font-weight: 200;
    }
  }
`
export const SubWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  p {
    color: #000000;
    font-weight: 500;
    padding: 0 1rem;
  }
  @media only screen and (min-width: 1024px) {
    flex-direction: row;
    width: 98%;
    margin-right: auto;
    p {
      line-height: 36px;
      font-size: 17px;
      margin-top: 120px;
      margin-right: 2rem;
      max-width: 370px;
      padding: 0;
      white-space: break-spaces;
    }
    img {
      margin-bottom: -150px;
      object-fit: cover;
      position: relative;
      ${props =>
        props.category === 22 &&
        css`
          margin-top: -69px;
        `}
    }
  }
  @media only screen and (min-width: 1200px) {
    width: 95%;
  }
`

export const Frame = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 6;
  iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
export const ViewButton = styled.button`
  display: none;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  display: block;
  background-color: rgba(33, 33, 33, 0.85);
  z-index: 1;
  border-radius: 0.5rem;
  color: #f7f2eb;
  text-transform: uppercase;
  padding: 0.5rem 2.5rem;
  font-size: 16px;
  border: unset;
  cursor: pointer;
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    width: 23px;
    height: 22px;
    background-image: url(${view});
  }
  @media only screen and (min-width: 1024px) {
    top: 50%;
    left: 45%;
    padding: 0.6rem 3rem;
    font-size: 17px;
  }
`

export const Close = styled.div`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 20px;
  span {
    display: block;
    height: 25px;
    width: 3px;
    background-color: #fff;
    position: absolute;
    &:nth-child(1) {
      transform: rotate(45deg);
    }
    &:nth-child(2) {
      transform: rotate(135deg);
    }
  }
  @media only screen and (min-width: 1024px) {
    right: 50px;
    top: 25px;
  }
`

export const ImageWrapper = styled.div`
  position: relative;
`
