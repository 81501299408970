import React, { useState } from "react"
import SubProductCard from "../UI/ProductCard/SubProductCard"
import CategoryQuery from "./CategoryQuery"
import {
  SubCatalogueWrapper,
  SubFlex,
  SubOrder,
  SubOrderWrapper,
  SubTitle,
} from "./styles"
import Saun from "../../assets/products/saun.png"
import Spa from "../../assets/products/spa.png"

const SubCatalogue = () => {
  const [parentCategory, setParentCategory] = useState(21)

  function setParent(id) {
    setParentCategory(id)
  }

  console.log("parentCategory", parentCategory)

  return (
    <SubCatalogueWrapper id={parentCategory}>
      <CategoryQuery
        selected={parentCategory && parentCategory}
        setParent={setParent}
      />
      <SubFlex>
        <SubTitle>
          {parentCategory === 21 ? (
            <h2>
              Moodulsaun <span>Spauna</span>
            </h2>
          ) : (
            <h2>
              Spamoodul <span>Spauna</span>
            </h2>
          )}
        </SubTitle>
        {parentCategory === 21 ? (
          <SubProductCard
            category={parentCategory}
            title="moodulsaun spauna"
            content="Spauna moodulsaun sobib oma disainilt nii loodusesse kui ka linnaruumi. Väga paljude võimalustega saun on lihtsasti paigaldatav ja valmis kohe kasutamiseks. Sinu poolt mõtted ja soovid, meie poolt teostus ja paigaldus."
            view="/public/saun"
            image={Saun}
          />
        ) : (
          <SubProductCard
            category={parentCategory}
            title="spamoodul spauna"
            content="Spauna moodulspaa on moodsa sisustuse ja paljude võimalustega. Sinu isiklik aurusaun pakub rõõmu nii Sulle kui ka Sinu perele. Naudi spaamõnusid oma koduaias."
            view="/public/aurusaun"
            image={Spa}
          />
        )}
      </SubFlex>
      <SubOrderWrapper>
        <SubOrder
          onClick={() =>
            document
              .getElementById("vorm")
              .scrollIntoView({ behavior: "smooth" })
          }
          id={parentCategory}
        >
          Tellimine
        </SubOrder>
      </SubOrderWrapper>
    </SubCatalogueWrapper>
  )
}

export default SubCatalogue
