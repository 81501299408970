import React from "react"
import { Wrapper } from "./styles"
import ScrollAnimation from "react-animate-on-scroll"

const TextCard = props => {
  const { title, children, variant } = props || {}

  return (
    <Wrapper variant={variant}>
      <ScrollAnimation animateIn="fadeIn">
        <h2>{title}</h2>
        <p>{children}</p>
      </ScrollAnimation>
    </Wrapper>
  )
}

export default TextCard
