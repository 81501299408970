import styled, { css } from "styled-components"
import next from "../../assets/illustration/next.svg"
import prev from "../../assets/illustration/prev.svg"
import saun from "../../assets/illustration/saun-bg.svg"
import spa from "../../assets/illustration/spa-bg.svg"

export const Wrapper = styled.div`
  padding-bottom: 2rem;
  ${props =>
    props.id === 21 &&
    css`
      background-color: #f7f2eb;
      background-image: url(${saun});
      background-size: contain;
      background-repeat: no-repeat;
      @media only screen and (min-width: 1024px) {
        background-position: top left;
      }
    `}
  ${props =>
    props.id === 22 &&
    css`
      background-color: #deeaec;
      @media only screen and (min-width: 1024px) {
        background-image: url(${spa});
        background-size: 50% 75%;
        background-repeat: no-repeat;
      }
    `}
  @media only screen and (min-width: 1024px) {
    padding: 2rem 0 2rem 2rem;
    border: none;
  }
`

export const OrderButton = styled.button`
  border: none;
  color: #fff;
  padding: 0.7rem 3rem;
  border-radius: 0.25rem;
  font-size: 15px;
  width: 230px;
  display: block;
  margin: auto;
  margin-top: 2rem;
  cursor: pointer;
  @media only screen and (min-width: 1024px) {
    width: 287px;
    margin-top: 3rem;
    margin-bottom: 1rem;
    font-size: 20px;
    padding: 1rem 3rem;
  }
  ${props =>
    props.id === 21 &&
    css`
      background: #7b6244;
      box-shadow: 0 5px 18px rgba(44, 23, 1, 38%);
    `}
  ${props =>
    props.id === 22 &&
    css`
      background: #3a686e;
      box-shadow: 0px 5px 22px rgba(0, 0, 0, 11%);
    `}
`

export const InnerWrapper = styled.div``

export const Flex = styled.div`
  @media only screen and (min-width: 1024px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 1500px;
    margin-left: auto;
    padding-top: 2rem;
    padding-bottom: 5rem;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    margin-top: 2rem;
    ${props =>
      props.image &&
      css`
        background-image: url(${props.image});
        background-size: cover;
      `}
  }
`

export const Title = styled.div`
  h2 {
    color: #2b2928;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    font-size: 25px;
    span {
      font-weight: 400;
    }
  }
  @media only screen and (min-width: 1024px) {
    flex: 0 0 100%;
    margin-top: 0;
    h2 {
      color: #fff;
      margin-top: 50px;
      margin-bottom: -20px;
      font-size: 56px;
      margin-left: 10%;
      text-align: left;
    }
    span {
      display: block;
    }
  }
`

export const Products = styled.div`
  padding-top: 4rem;
  .swiper-button-next {
    &:after {
      display: none;
    }
  }
  .swiper-button-prev {
    &:after {
      display: none;
    }
  }
  .swiper-container {
    margin-top: -8rem;
    padding-bottom: 2rem;
  }
  ${props =>
    props.category === 21 &&
    css`
      background-image: url(${props.image});
      background-size: cover;
      background-repeat: no-repeat;
    `}
  ${props =>
    props.category === 22 &&
    css`
      background-image: url(${props.image});
      background-size: cover;
      background-repeat: no-repeat;
    `}
  @media only screen and (min-width: 1024px) {
    padding-top: 0;
    max-width: 650px;
    width: 100%;
    background-image: unset;
    .swiper-container {
      padding-top: 200px;
      margin-top: -200px;
      padding-bottom: 0;
    }
    .swiper-button-next {
      top: calc(50% + 70px);
      background-image: url(${next});
      background-size: cover;
      background-repeat: none;
      height: 80px;
      width: 80px;
      &:after {
        display: none;
      }
    }
    .swiper-button-prev {
      top: calc(50% + 70px);
      background-image: url(${prev});
      background-size: cover;
      background-repeat: none;
      height: 80px;
      width: 80px;
      &:after {
        display: none;
      }
    }
  }
`

export const SubCatalogueWrapper = styled.div`
  padding-bottom: 2rem;
  ${props =>
    props.id === 21 &&
    css`
      background-color: #f7f2eb;
      background-image: url(${saun});
      background-size: contain;
      background-repeat: no-repeat;
      @media only screen and (min-width: 1024px) {
        background-position: top left;
      }
    `}
  ${props =>
    props.id === 22 &&
    css`
      background-color: #deeaec;
      @media only screen and (min-width: 1024px) {
        background-image: url(${spa});
        background-size: 50% 75%;
        background-repeat: no-repeat;
      }
    `}
  @media only screen and (min-width: 1024px) {
    padding: 2rem 0 2rem 2rem;
    border: none;
  }
`

export const SubProducts = styled.div``

export const SubFlex = styled.div`
  @media only screen and (min-width: 1024px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 1500px;
    margin-left: auto;
    padding-top: 2rem;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
`
export const SubTitle = styled.div`
  h2 {
    color: #000;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    font-size: 25px;
    span {
      font-weight: 400;
    }
  }
  @media only screen and (min-width: 1024px) {
    margin-bottom: -90px;
    flex: 0 0 100%;
    margin-top: 0;
    h2 {
      margin-top: 50px;
      font-size: 72px;
      text-align: left;
    }
    span {
      display: block;
    }
  }
`

export const SubOrderWrapper = styled.div`
  @media only screen and (min-width: 1024px) {
    max-width: 1500px;
    margin-left: auto;
  }
`

export const SubOrder = styled.button`
  border: none;
  color: #fff;
  padding: 0.7rem 3rem;
  border-radius: 0.25rem;
  font-size: 15px;
  width: 230px;
  display: block;
  margin: auto;
  margin-top: 2rem;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  @media only screen and (min-width: 1024px) {
    margin: unset;
    width: 287px;
    margin-top: 1rem;
    margin-bottom: 0rem;
    font-size: 20px;
    padding: 1rem 3rem;
  }
  @media only screen and (min-width: 1200px) {
    margin-top: -1rem;
    margin-bottom: 1rem;
  }
  @media only screen and (min-width: 1350px) {
    margin-top: -4rem;
    margin-bottom: 1.5rem;
  }
  @media only screen and (min-width: 1450px) {
    margin-top: -7rem;
    margin-bottom: 4rem;
  }
  @media only screen and (min-width: 1500px) {
    margin-top: -9rem;
    margin-bottom: 6rem;
  }
  @media only screen and (min-width: 1550px) {
    margin-top: -11rem;
    margin-bottom: 8rem;
  }
  ${props =>
    props.id === 21 &&
    css`
      background: #7b6244;
      box-shadow: 0 5px 18px rgba(44, 23, 1, 38%);
    `}
  ${props =>
    props.id === 22 &&
    css`
      background: #3a686e;
      box-shadow: 0px 5px 22px rgba(0, 0, 0, 11%);
    `}
`
