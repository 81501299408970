import styled from "styled-components"
import textbg from "../../assets/illustration/textbg.svg"

export const Wrapper = styled.div`
  margin: 4rem 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .swiper-container {
    width: 100%;
    .swiper-pagination {
      bottom: 0;
    }
  }
  h2 {
    flex: 0 0 100%;
    color: #3f4a59;
    text-align: center;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 32px;
  }

  @media only screen and (min-width: 1024px) {
    margin: 8rem 0;
    h2 {
      margin-bottom: 3rem;
      font-size: 45px;
      text-align: left;
    }
  }
  @media only screen and (min-width: 1200px) {
    h2 {
      margin-left: 10%;
    }
  }
`

export const LogosWrapper = styled.div`
  display: none;
  flex-wrap: wrap;
  justify-content: center;
  @media only screen and (min-width: 1024px) {
    display: flex;
    width: 45%;
    align-items: center;
  }
`

export const LogosInnerWrapper = styled.div`
  flex: 0 0 50%;
  align-items: center;
  max-height: 150px;
  display: flex;
  img {
    cursor: pointer;
    max-height: 200px;
  }
  @media only screen and (max-width: 374px) {
    img {
      max-width: 120px;
    }
  }
  @media only screen and (min-width: 1024px) {
    img {
      margin-bottom: 0;
    }
  }
`

export const TextWrapper = styled.div`
  height: 500px;
  width: 100%;
  position: relative;
  background-image: url(${textbg});
  background-position: center;
  background-size: 134% 135%;
  margin: 1rem 0;
  @media only screen and (max-width: 374px) {
    background-size: 168% 135%;
  }
  @media only screen and (min-width: 768px) {
    margin: 2rem 0;
    height: 410px;
    background-size: 100% 125%;
  }
  @media only screen and (min-width: 1024px) {
    height: auto;
    background-size: 120%;
    display: block;
    width: 55%;
    min-height: 600px;
    margin: 0;
    img {
      display: none;
    }
  }
  @media only screen and (min-width: 1200px) {
    width: 45%;
  }
`
export const TopRight = styled.div`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  @media only screen and (min-width: 1024px) {
    display: block;
  }
`

export const BottomLeft = styled.div`
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  @media only screen and (min-width: 1024px) {
    display: block;
  }
`

export const BottomRight = styled.div`
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  @media only screen and (min-width: 1024px) {
    display: block;
  }
`

export const TextInnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 21%;
  transform: translateY(-50%);
  img {
    max-width: 100px;
    max-height: 60px;
    margin-bottom: 1rem;
  }
  h4 {
    display: none;
  }
  p {
    width: 200px;
    font-size: 13px;
    font-weight: 500;
    line-height: 25px;
  }
  @media only screen and (min-width: 768px) {
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @media only screen and (min-width: 1024px) {
    height: auto;
    padding: 0;
    position: absolute;
    transform: unset;
    left: 29%;
    top: 25%;
    max-width: 250px;
    h4 {
      display: block;
    }
    p {
      width: auto;
      position: relative;
      font-size: 16px;
      max-width: 250px;
      line-height: 30px;
      transform: unset;
      top: unset;
      left: unset;
    }
  }
`
