import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import CategoryCard from "../UI/CategoryCard"
import { CategoryButton } from "../UI/CategoryCard/styles"

export default props => {
  const data = useStaticQuery(graphql`
    query {
      allWpProductCategory {
        nodes {
          id
          databaseId
          name
          image {
            localFile {
              childImageSharp {
                id
                original {
                  src
                }
              }
            }
          }
          wpParent {
            node {
              id
            }
          }
        }
      }
    }
  `)

  let i = 0

  return (
    <CategoryCard>
      <h3>Tooted</h3>
      {data &&
        data?.allWpProductCategory?.nodes.map(cat => {
          if (cat?.wpParent === null) {
            i++
            if (props.image === null) {
              if (i === 1) {
                props.setImage(
                  cat?.image?.localFile?.childImageSharp?.original?.src
                )
              }
            }
            return (
              <CategoryButton
                name={cat.name}
                onClick={() => {
                  props.setParent(cat.databaseId)
                  if (props.setImage)
                    props.setImage(
                      cat.image?.localFile.childImageSharp.original.src
                    )
                }}
                selected={props.selected === cat.id && "selected"}
              >
                {cat.name}
              </CategoryButton>
            )
          }
        })}
    </CategoryCard>
  )
}
