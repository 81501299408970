import styled, { css } from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  h3 {
    color: #3f4a59;
    margin: 0;
    display: none;
  }
  @media only screen and (min-width: 1024px) {
    margin-left: auto;
    margin-top: 1rem;
    max-width: 1500px;
    padding: 0;
    justify-content: unset;
    h3 {
      display: flex;
      font-size: 42px;
      margin-right: 3rem;
    }
  }
`
export const CategoryButton = styled.button`
  background-color: transparent;
  border: none;
  font-weight: 700;
  color: #7b6244;
  margin: 0 1rem;
  font-size: 18px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  ${props =>
    props.selected === "selected" &&
    css`
      border-bottom: 2px solid;
      @media only screen and (min-width: 1024px) {
        border-bottom: 5px solid;
      }
    `}
  ${props =>
    props.name === "SPA" &&
    css`
      color: #3a686e;
    `}
  @media only screen and (min-width: 1024px) {
    margin: 0 2.5rem;
    font-size: 32px;
    padding-bottom: 0.5rem;
  }
`
