import React from "react"
import SwiperCore, { A11y, Navigation, Pagination, Scrollbar } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.css"
import harvia from "../../assets/logos/harvia.svg"
import hexor from "../../assets/logos/hexor.png"
import huum from "../../assets/logos/huum.svg"
import qualitystone from "../../assets/logos/qualitystone.jpg"
import saunum from "../../assets/logos/Saunum.svg"
import unknown from "../../assets/logos/unknown.svg"
import PartnerText from "./PartnerText"
import { Wrapper } from "./styles"

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])

const content = [
  {
    id: 1,
    title: "Saunum",
    src: saunum,
    content:
      "Saunumi ainulaadne tehnoloogia pakub nauditavamat ja tervislikumat saunaelamust. Tõeliselt kaasaegset ja põhjamaist tehnoloogiat, mis muudab saunatamise kõigile nauditavaks, kadestavad isegi soomlased.",
  },
  {
    id: 2,
    title: "Qualitystone",
    src: qualitystone,
    content:
      "Käsitsi lõigatud unikaalsed marmorist kivimosaiigid ja -plaadid saunade, spaade, vannitubade, terrasside, köökide ja muu jaoks.",
  },
  {
    id: 3,
    title: "Huum",
    src: huum,
    content:
      "Eesti ettevõtte HUUMi keriste ümar ja minimalistlik disain rõhutab kivide loomulikku ilu ning elavdab leiliruumi sisekujundust. Huumi kerised, mida juhib tehnoloogia läbi sinu nutiseadme, on saunamaailma revolutsioon. ",
  },
  {
    id: 4,
    title: "Hexor",
    src: hexor,
    content:
      "HEXOR termohaavast paneelid sobivad hästi nii elutuppa, sauna, trepihalli kui ka magamistuppa. Efekti lisavad kindlasti veel samast seeriast tumedad seinavalgustid. Eestimaiselt naturaalne ja kaunis.",
  },
  {
    id: 5,
    title: "Harvia",
    src: harvia,
    content:
      "Soome ettevõte, mis on arenenud saunakeriste tootjast tunnustatud sauna- ja spaabrändiks, mille tooteid ja lahendusi võib leida rohkem kui 80 riigis üle maailma. Meil on tugev koostööpartner Harvia Estonia näol.",
  },
  {
    id: 6,
    title: "Khis",
    src: unknown,
    content:
      "Iga KHIS vann on unikaalne ja käsitsi tehtud: alates puulippide lõikamisest, nende viimistlusest kuni vanni kokku panemiseni. Aeg tagab täpsuse, pühendumine tagab kvaliteedi.TTunneta kvaliteetpuidu soojust ja luksust oma vannitoas.",
  },
]

const PartnerMobile = props => {
  return (
    <Wrapper>
      <h2>Meie partnerid</h2>
      <Swiper slidesPerView={1} pagination={{ clickable: true }}>
        {content.map(c => {
          return (
            <SwiperSlide key={c.id}>
              <PartnerText key={c.id} title={c.title} text={c.content}>
                <img src={c.src} />
              </PartnerText>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </Wrapper>
  )
}

export default PartnerMobile
