import styled, { css } from "styled-components"

export const Wrapper = styled.div`
  margin: 2rem 0;
  h2 {
    text-align: center;
  }
  div {
    margin: 1rem auto;
    max-width: 560px;
  }
  label {
    display: block;
    margin-bottom: 0.25rem;
  }

  @media only screen and (min-width: 1024px) {
    h2 {
      font-size: 45px;
      margin-bottom: 3rem;
    }
  }
`

export const InputWrapper = styled.div`
  textarea {
    width: 100%;
    border-color: #7b6244;
    border-radius: 0.25rem;
    padding: 0.25rem;
  }
  input {
    width: 100%;
    border: 1px solid #7b6244;
    border-radius: 0.25rem;
    padding: 0.25rem;
  }
  ${props =>
    props.vale &&
    css`
      opacity: 0;
      position: absolute;
      height: 0;
      width: 0;
      z-index: -1;
    `}
`
